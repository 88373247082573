import clsx from "clsx";
import Link from "next/link"
import { useRef, useState } from "react";
import useVisible from "../../../hooks/useVisible";
import PromoSlider from "./PromoSlider";

const Promo = ({data, controls, isIE}) => {
  //animation
  const ref = useRef(null)
  const { isVisible: inView } = useVisible(ref, 1)

    return (
        <section className='promo' ref={ref}>
          <div className='promo__inner'>
            <div className='promo__title'>
              <h2>MYPRIORITY БАСМАННЫЙ</h2>
              <span>ЭТО</span>
            </div>
            <PromoSlider data={data} isIE={isIE}/>
            <Link href={"https://domrfbank.ru/"}>
              <a className='promo__fin' target="_blank" rel="noreferrer">
                <img src={"/files/images/main/constr-fin-logo.png"} alt="Logo fin"/>
              </a>
            </Link>
          </div>

          <div className={clsx('promo__controls', inView && '__animation')}>
            {controls.map((control, i) => (
              <Link href={control.link} key={control.id}>
                <div className="promo__controls-btn"
                     style={{
                         background: control.bg_color ? control.bg_color : "#63729A",
                         color: control.text_color ? control.text_color : "#FFFFFF",
                         '--offset': i,
                }}>
                  {control.name && <p>{control.name}</p>}
                  {control.image && <img src={`${process.env.NEXT_PUBLIC_API_LOCAL_PATH}/${control.image}`} alt="control_image"/>}
                </div>
              </Link>
            ))}
          </div>
        </section>
    )
}

export default Promo